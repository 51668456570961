import React, { useContext } from "react";
import { DataContext } from "../../context/context";
import TopMenu from "../top-menu";
import Account from "../account";
import { Styled } from "./header.styled";
import CompanyLogo from "../../images/companylogo.png";

const Header = () => {
  const { state } = useContext(DataContext);

  return (
    <Styled.MainBox>
      <Styled.InsideBox>
        <Styled.BoxLeft>
          <Styled.BoxCompany>
            <Styled.BoxCompanyLogo
              $isResize={state.user_type === "research_director"}
              draggable="false"
              src={CompanyLogo}
            />
          </Styled.BoxCompany>
        </Styled.BoxLeft>
        {state.user_type === "research_director" && (
          <Styled.BoxCenter>
            <TopMenu />
          </Styled.BoxCenter>
        )}
        <Styled.BoxRight>
          <Styled.BoxAccount>
            <Account />
          </Styled.BoxAccount>
        </Styled.BoxRight>
      </Styled.InsideBox>
    </Styled.MainBox>
  );
};

export default Header;

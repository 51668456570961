import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 70px;
  flex-wrap: wrap;
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Styled = {
  MainBox,
  LeftBox,
  RightBox,
};

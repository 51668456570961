import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Styled } from "./account.styled";
import { ModalStyle } from "../../pages/login/StyledComponents";
import Login from "../../pages/login";
// import ResetPassword from "../resetPassword"
import { DataContext } from "../../context/context";
// import { deleteCookie } from "../../middleware/auth";
import { stateObj } from "../../const/contexts";
import avatar from "../../images/avatar.png";

const Account = () => {
  const { state, handlerSet } = useContext(DataContext);

  const [loginModalVisible, setLoginModalVisible] = useState(true);
  const [isLog, setIsLog] = useState(true); // false fix if you want show modal login

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    // fix add request is valid token user
    if (state.user_name) {
      setIsLog(true);
      setLoginModalVisible(false);
    }
  }, []);

  const setDefaultState = () => {
    handlerSet(stateObj);
  };

  const logout = () => {
    // deleteCookie()
    // .then(() => {
    //   setDefaultState();
    //   navigate("/login");
    // })
    // .catch(() => null);
  };

  const handleAuthorized = (value) => {
    setLoginModalVisible(false);
    setIsLog(true);
    handlerSet({
      // fix
      // user_id: value.id,
      user_name: value.user_name,
    });
  };

  const items = [{ label: t("account.menuItems.logout"), key: "Logout" }];

  const handleMenuClick = (e) => {
    if (e.key === "Logout") {
      setLoginModalVisible(true);
      setIsLog(false);
      // logout(); // fix in future
    }
  };

  return isLog ? (
    <Styled.MainBox>
      <Styled.BoxAvatar>
        <Styled.BoxAvatarImg src={avatar} />
      </Styled.BoxAvatar>
      <Styled.BoxDropDown>
        <Dropdown
          menu={{
            items,
            onClick: handleMenuClick,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Styled.NameStyle> {state.user_name}</Styled.NameStyle>
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </a>
        </Dropdown>
      </Styled.BoxDropDown>

      <Styled.BoxSetting>
        <Styled.SettingButton color="#555B6D" />
      </Styled.BoxSetting>
    </Styled.MainBox>
  ) : (
    <>
      <Button
        key="1"
        type="primary"
        onClick={() => setLoginModalVisible(true)}
        onKeyPress={() => setLoginModalVisible(true)}
      >
        {t("account.loginButton")}
      </Button>
      <ModalStyle
        open={loginModalVisible || !isLog}
        footer={[]}
        closable={false}
        centered
      >
        <Login
          setIsVisible={setLoginModalVisible}
          handleAuthorized={handleAuthorized}
          setIsLog={setIsLog}
        />
      </ModalStyle>
    </>
  );
};

export default Account;

import { Select } from "antd";
import styled from "styled-components";
import { ReactComponent as grid_icon } from "../../../images/grid_icon.svg";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  padding: 18px;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #495057;
  margin-right: 20px;
`;

export const HeaderControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderName2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #495057;
`;

export const GroupNameSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectStyled = styled(Select)``;

export const GroupNameSelectAdd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
`;

export const HeaderNameAdd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #74788d;
  margin-right: 20px;
`;

export const GroupChartAndRemove = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ChartBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RemoveBox = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;
`;

export const RemoveIcon = styled(grid_icon)`
  position: absolute;
  top: ${(props) => props.top + "px"};
  left: 0px;
`;

export const Styled = {
  MainBox,
  HeaderBox,
  HeaderName,
  HeaderControl,
  HeaderName2,
  GroupNameSelect,
  SelectStyled,
  HeaderNameAdd,
  GroupNameSelectAdd,

  GroupChartAndRemove,
  ChartBox,
  RemoveBox,
  RemoveIcon,
};

import styled from "styled-components";
import { ReactComponent as green_arrow } from "../../../images/green_arrow.svg";
import { ReactComponent as red_arrow } from "../../../images/red_arrow.svg";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  padding: 18px;
  /* width: 60vw; */
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #495057;
`;

export const HeaderControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GroupContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
`;

export const TodayBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TodayBoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #74788d;
`;

export const TodayBoxValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.2px;
  line-height: 24px;

  color: #495057;
`;

export const MonthBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MonthBoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #74788d;
`;

export const MonthBoxGroupValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MonthBoxValueNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.2px;
  line-height: 24px;

  color: #495057;
`;

export const MonthBoxValuePercent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #34c38f;
  margin-left: 4px;
  margin-right: 9px;
`;

export const MonthBoxValueArrow = styled.div``;

export const MonthValueArrow = styled(green_arrow)`
  width: 8px;
  height: 8px;
`;

export const YearBox = styled(MonthBox)``;

export const ItemValueArrowUp = styled(green_arrow)`
  width: 8px;
  height: 8px;
`;

export const ItemValueArrowDown = styled(red_arrow)`
  width: 8px;
  height: 8px;
`;

export const Styled = {
  MainBox,
  HeaderBox,
  HeaderName,
  HeaderControl,
  GroupContent,
  TodayBox,
  TodayBoxTitle,
  TodayBoxValue,
  MonthBox,
  MonthBoxTitle,
  MonthBoxGroupValue,
  MonthBoxValueNumber,
  MonthBoxValuePercent,
  MonthBoxValueArrow,
  MonthValueArrow,
  YearBox,
  ItemValueArrowUp,
  ItemValueArrowDown,
};

import { formatDistanceStrict } from "date-fns";
import { de } from "date-fns/locale";
import moment from "moment";

export const getYear = () => {
  const year = new Date().getFullYear();
  return year;
};

export const cutText = (value, count) => {
  let text = "";
  if (value.length > count + 2) {
    text = `${value.slice(0, count)}...`;
  } else text = value;

  return text;
};

export const getDayYear = () => {
  const year = new Date().toLocaleString("default", { month: "long" });
  const day = new Date().getUTCDate();
  return `${day}  ${year}`;
};

export const getTime = () => {
  const now = new Date();
  const h = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const m = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  return `${h}:${m}`;
};

export const getTimeScenarioList = (timeBefore) => {
  const res = formatDistanceStrict(new Date(timeBefore), new Date(), {
    locale: de,
  });

  if (res.includes("Tag")) {
    if (Number(res.split(" ")[0]) > 7)
      return `${Math.floor(Number(res.split(" ")[0]) / 7) + 1} Wochen`;
  }

  return res;
};

function isArrayEquals(prev, curr) {
  return (
    Array.isArray(prev) &&
    Array.isArray(curr) &&
    prev.length === curr.length &&
    prev.every((val, index) => val === curr[index])
  );
}

export const isEquelTwoObj = (prev, curr) => {
  const res =
    prev.plate !== curr.plate ||
    prev.name !== curr.name ||
    prev.type !== curr.type ||
    Number(prev.max_speed) !== Number(curr.max_speed) ||
    (prev.max_speed === undefined && curr.max_speed === undefined) ||
    prev.notes !== curr.notes ||
    !isArrayEquals(
      prev?.vehicles_driving_licensesValue?.sort(),
      curr?.vehicles_driving_licensesValue?.sort()
    ) ||
    prev.vehicle_manufacturersValue !== curr.vehicle_manufacturersValue;

  return res;
};

export const isValueObjChange = (prev, curr) => {
  const res =
    (prev?.plate !== curr?.plate && curr?.plate !== "") ||
    (prev?.name !== curr?.name && curr?.name !== "") ||
    prev?.type !== curr?.type ||
    (curr?.max_speed !== undefined && curr?.max_speed !== "") ||
    (prev?.notes !== curr?.notes && curr?.notes !== "") ||
    !isArrayEquals(
      prev?.vehicles_driving_licensesValue?.sort(),
      curr?.vehicles_driving_licensesValue?.sort()
    ) ||
    curr?.vehicle_manufacturersValue !== null;

  return res;
};

export const isFilledRequiredFields = (prev, curr) =>
  curr.plate !== undefined &&
  curr.plate !== "" &&
  curr.name !== undefined &&
  curr.name !== "";

export const getIdForLicenses = (value) =>
  value.vehicles_driving_licensesValue.map((item) => ({
    id: value.vehicles_driving_licenses.find(
      (itemFind) => itemFind.name === item
    ).id,
    name: item,
  }));

export const formMissedQuestions = (allQuestions) =>
  allQuestions
    .map((questionItem) => {
      const { name } = questionItem;
      const question = questionItem.questionnaires_questions.filter(
        (item) =>
          item.question.test_subjects_questions_answers.length === 0 &&
          item.question?.questions_type.name !== "post-ride-events" // filtered last temp question
      );
      return question.map((item, index) => ({
        question: name,
        questionText: item.question.question,
        counts: index === 0 ? question.length : 0,
      }));
    })
    .reduce((prev, curr) => [...prev, ...curr], [])
    .map((itemArr, index) => ({
      key: index,
      ...itemArr,
    }));

export const isFilledRequiredFieldsTestGroup = (prev, curr) =>
  curr.name !== undefined && curr.name !== "";

export const isEquelTwoObjTestGroup = (prev, curr) => {
  const res =
    prev.name !== curr.name ||
    prev.description !== curr.description ||
    curr?.selectedUserArr?.length !== 0;

  return res;
};

export const isValueObjChangeTestGroup = (prev, curr) => {
  const res =
    (prev?.name !== curr?.name && curr?.name !== "") ||
    (prev?.description !== curr?.description && curr?.description !== "") ||
    curr?.selectedUserArr?.length !== 0;

  return res;
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);

  return today.getFullYear() - birthDate.getFullYear();
};

export const makeSalt = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const DeleteRow = (data, index) => {
  return data.map((item) => ({
    name: item.name,
    data: [...item.data.filter((item2, index2) => index2 !== index)],
  }));
};

export const DeleteByIndex = (data, index) =>
  data.filter((item2, index2) => index2 !== index);

export const InsertRow = (data, row) =>
  data.map((item, index) => ({
    name: item.name,
    data: [...item.data, row[index]],
  }));

export const InsertNewValue = (data, value) => [...data, value];

const getMinDate = (data) => {
  for (const el in data) {
    return getCurrentDayFromDate(el);
  }
};
// diagram1
export const convertDataToDayStep = (data, buttonDateName) => {
  let workedBySusiObj = {};
  let workedByDisponentObj = {};
  let line3ObjSumSusiDisponent = {};
  let line4ObjSusiPrevPeriod = {};
  let line5ObjDisponentPrevPeriod = {};
  let line6ObjSumSusiDisponentPrevPeriod = {};
  let dateArr = [];
  let count = 0;

  const minDate = getMinDate(data);
  let day = getInterval(buttonDateName, "current", undefined, minDate)[0];

  while (
    new Date(
      getInterval(buttonDateName, "current", undefined, minDate)[0]
    ).getTime() <= new Date(day).getTime() &&
    new Date(day).getTime() <=
      new Date(
        getInterval(buttonDateName, "current", undefined, minDate)[1]
      ).getTime()
  ) {
    const value =
      buttonDateName === "6M" || buttonDateName === "1Y"
        ? getCurrentMonthFromDate(getDateFromMoment(day))
        : getCurrentDayFromDate(getDateFromMoment(day));

    if (workedBySusiObj[value] === undefined) workedBySusiObj[value] = 0;

    if (workedByDisponentObj[value] === undefined)
      workedByDisponentObj[value] = 0;

    if (line3ObjSumSusiDisponent[value] === undefined)
      line3ObjSumSusiDisponent[value] = 0;

    // Susi PrevPeriod (prew week or 6m or year)
    if (line4ObjSusiPrevPeriod[value] === undefined)
      line4ObjSusiPrevPeriod[value] = 0;

    // Disponent PrevPeriod (prew week or 6m or year)
    if (line5ObjDisponentPrevPeriod[value] === undefined)
      line5ObjDisponentPrevPeriod[value] = 0;

    // Susi PrevPeriod + Disponent PrevPeriod
    if (line6ObjSumSusiDisponentPrevPeriod[value] === undefined)
      line6ObjSumSusiDisponentPrevPeriod[value] = 0;

    day = getNextDay(day);
  }

  // current period
  for (const el in data) {
    const value =
      buttonDateName === "6M" || buttonDateName === "1Y"
        ? getCurrentMonthFromDate(el)
        : getCurrentDayFromDate(el);

    if (
      new Date(
        getInterval(buttonDateName, "current", undefined, minDate)[0]
      ).getTime() <= new Date(el).getTime() &&
      new Date(getDateOrCurrentDate(el)).getTime() <=
        new Date(
          getInterval(buttonDateName, "current", undefined, minDate)[1]
        ).getTime()
    ) {
      workedBySusiObj[value] += data[el].calcbysusi;

      workedByDisponentObj[value] += data[el].calcbydisp;

      //  Susi+Disponent
      line3ObjSumSusiDisponent[value] +=
        data[el].calcbydisp + data[el].calcbysusi;
    }
  }

  // prev period
  for (const el in data) {
    const value =
      buttonDateName === "6M" || buttonDateName === "1Y"
        ? getCurrentMonthFromDate(el)
        : getCurrentDayFromDate(el);

    if (
      new Date(
        getInterval(buttonDateName, "prev", undefined, minDate)[0]
      ).getTime() <= new Date(el).getTime() &&
      new Date(getDateOrCurrentDate(el)).getTime() <=
        new Date(
          getInterval(buttonDateName, "prev", undefined, minDate)[1]
        ).getTime()
    ) {
      // Susi PrevPeriod (prew week or 6m or year)
      line4ObjSusiPrevPeriod[getNextIntervalDate(buttonDateName, value)] +=
        data[el].calcbysusi;

      // Disponent PrevPeriod (prew week or 6m or year)
      line5ObjDisponentPrevPeriod[getNextIntervalDate(buttonDateName, value)] +=
        data[el].calcbydisp;

      // Susi PrevPeriod + Disponent PrevPeriod
      line6ObjSumSusiDisponentPrevPeriod[
        getNextIntervalDate(buttonDateName, value)
      ] += data[el].calcbydisp + data[el].calcbysusi;
    }
  }

  return [
    {
      name: "Aktuell",
      data: Object.entries(line3ObjSumSusiDisponent),
    },
    {
      name: "von SUSI beantwortet",
      data: Object.entries(workedBySusiObj),
    },
    {
      name: "von Disponenten beantwortet",
      data: Object.entries(workedByDisponentObj),
    },
    {
      name: "vorheriger Zeitraum",

      data: Object.entries(line6ObjSumSusiDisponentPrevPeriod),
    },
    {
      name: "von SUSI beantwortet vorheriger Zeitraum",
      data: Object.entries(line4ObjSusiPrevPeriod),
    },
    {
      name: "von Disponenten beantwortet vorheriger Zeitraum",
      data: Object.entries(line5ObjDisponentPrevPeriod),
    },
  ];
};

export const getDateFromMoment = (data) => {
  const date = new Date(data);

  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + month + "-" + day;
};

export const getDateOrCurrentDate = (data) => {
  const date = data === undefined ? new Date() : new Date(data);

  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + month + "-" + day;
};

export const getCurrentMonth = () => {
  const date = new Date();

  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + month + "-" + "01";
};

export const getCurrentYear = () => {
  const date = new Date();

  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + "01" + "-" + "01";
};

// prev
export const getPrevMonth = () =>
  moment(getDateOrCurrentDate())
    .subtract(6, "month")
    .add(1, "d")
    .format("YYYY-MM-DD");

export const getPrevYear = () =>
  moment(getDateOrCurrentDate())
    .subtract(1, "year")
    .add(1, "d")
    .format("YYYY-MM-DD");

export const getCurrentDayFromDate = (data) => data.split("T")[0];

export const getCurrentMonthFromDate = (data) => {
  const date = new Date(data);

  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + month + "-" + "01";
};

export const getCurrentYearFromDate = (data) => {
  const date = new Date(data);

  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + "01" + "-" + "01";
};

export const getSumForCurrentDay = (data) => {
  let sum = 0;

  const currentDay = getDateOrCurrentDate();
  for (const el in data) {
    const value = getCurrentDayFromDate(el);

    if (value === currentDay) sum += data[el].calcbysusi + data[el].calcbydisp;
  }
  return sum;
};

export const getSum = (data, buttonDateName, selectPeriod) => {
  let sum = 0;

  for (const el in data) {
    if (
      new Date(
        getInterval(buttonDateName, selectPeriod, undefined, undefined)[0]
      ).getTime() <= new Date(el).getTime() &&
      new Date(getDateOrCurrentDate(el)).getTime() <=
        new Date(
          getInterval(buttonDateName, selectPeriod, undefined, undefined)[1]
        ).getTime()
    ) {
      sum += data[el].calcbysusi + data[el].calcbydisp;
    }
  }

  return sum;
};

export const getSumForPrevMonth = (data) => {
  let sum = 0;

  const currentMonth = getCurrentMonth();
  for (const el in data) {
    const value = getCurrentMonthFromDate(el);

    if (value === currentMonth)
      sum += data[el].workedBySusi + data[el].workedByDisponent;
  }
  return sum;
};

// diagram2
export const convertDataToDayStepDiagram2 = (data, buttonDateName) => {
  let transmitted_delivery_date_requestsObj = {};
  let Delivery_date_requests_to_be_processed_by_dispatchersObj = {};
  let open_delivery_date_requestsObj = {};

  const minDate = getMinDate(data);
  let day = getInterval(buttonDateName, "current", undefined, minDate)[0];

  while (
    new Date(
      getInterval(buttonDateName, "current", undefined, minDate)[0]
    ).getTime() <= new Date(day).getTime() &&
    new Date(day).getTime() <=
      new Date(
        getInterval(buttonDateName, "current", undefined, minDate)[1]
      ).getTime()
  ) {
    const value =
      buttonDateName === "6M" || buttonDateName === "1Y"
        ? getCurrentMonthFromDate(getDateFromMoment(day))
        : getCurrentDayFromDate(getDateFromMoment(day));

    if (transmitted_delivery_date_requestsObj[value] === undefined)
      transmitted_delivery_date_requestsObj[value] = 0;

    if (
      Delivery_date_requests_to_be_processed_by_dispatchersObj[value] ===
      undefined
    )
      Delivery_date_requests_to_be_processed_by_dispatchersObj[value] = 0;

    if (open_delivery_date_requestsObj[value] === undefined)
      open_delivery_date_requestsObj[value] = 0;

    day = getNextDay(day);
  }

  // current period
  for (const el in data) {
    const value =
      buttonDateName === "6M" || buttonDateName === "1Y"
        ? getCurrentMonthFromDate(el)
        : getCurrentDayFromDate(el);

    if (
      new Date(
        getInterval(buttonDateName, "current", undefined, minDate)[0]
      ).getTime() <= new Date(el).getTime() &&
      new Date(getDateOrCurrentDate(el)).getTime() <=
        new Date(
          getInterval(buttonDateName, "current", undefined, minDate)[1]
        ).getTime()
    ) {
      transmitted_delivery_date_requestsObj[value] += data[el].automated;

      Delivery_date_requests_to_be_processed_by_dispatchersObj[value] +=
        data[el].disponent;

      open_delivery_date_requestsObj[value] += data[el].open;
    }
  }

  const keyCategory = Object.keys(transmitted_delivery_date_requestsObj);

  return {
    series: [
      {
        name: "getätigte Lieferterminanfragen",
        data: Object.values(transmitted_delivery_date_requestsObj),
      },
      {
        name: "von Disponenten zu bearbeitende Lieferterminanfragen",
        data: Object.values(
          Delivery_date_requests_to_be_processed_by_dispatchersObj
        ),
      },
      {
        name: "noch offene Lieferterminanfragen",
        data: Object.values(open_delivery_date_requestsObj),
      },
    ],
    categories: keyCategory,
  };
};

// diagram3
export const getSumDiagram3 = (data) =>
  data.mbt_prozess_false + data.mbt_prozess_true;

export const convertDataForDiagram3 = (data) => {
  return {
    chart1: {
      series: [
        {
          name: "Anzahl getätigter Lieferterminanfragen",
          data: [data.mbt_prozess_false],
        },
        {
          name: "Anzahl getätigter Lieferterminanfragen mit MBT-Sonderprozess",
          data: [data.mbt_prozess_true],
        },
      ],
      categories: ["DI12345"],
    },
    chart2: {
      series: [
        {
          name: "getätigte Lieferterminanfragen",
          data: Object.values(data.creditors),
        },
      ],
      categories: Object.keys(data.creditors),
    },
  };
};

// diagram 4,5

export const getKeysDiagram4 = (data) => {
  return Object.keys(data);
};

export const getDataForSelectDiagram4 = (data) => {
  return Object.keys(data).map((item) => ({
    value: item,
    label: item,
  }));
};

export const getSum1YForDiagram4 = (data) => {
  let sum = 0;
  for (const el in data) {
    sum += data[el].LTA + data[el].LTV;
  }
  return sum;
};

export const convertDataForDiagram4 = (data, select1Element) => {
  let lta = [];
  let ltv = [];

  for (const el in data) {
    lta.push(data[el].LTA);
    ltv.push(data[el].LTV);
  }

  return {
    chart1: {
      series: [
        {
          name: "transmitted delivery date requests",
          data: [
            data[select1Element] === undefined ? 0 : data[select1Element].LTA,
          ],
        },
        {
          name: "Delivery date requests to be processed by dispatchers",
          data: [
            data[select1Element] === undefined ? 0 : data[select1Element].LTV,
          ],
        },
      ],
    },
    chart2: {
      series: [
        {
          name: "verbindliche Lieferterminanfragen",
          data: lta,
        },
        {
          name: "Lieferterminverkürzungen",
          data: ltv,
        },
      ],
      categories: getKeysDiagram4(data),
    },
  };
};

export const getSum1YForDiagram5 = (data) => {
  let sum = 0;
  for (const el in data) {
    sum +=
      data[el].verzoegerung_true +
      data[el].verzoegerung_false +
      data[el].mbt_prozess_true;
  }
  return sum;
};

export const convertDataForDiagram5 = (data, select1Element) => {
  let verzoegerung_true = [];
  let verzoegerung_false = [];
  let mbt_prozess_true = [];

  for (const el in data) {
    verzoegerung_true.push(data[el].verzoegerung_true);
    verzoegerung_false.push(data[el].verzoegerung_false);
    mbt_prozess_true.push(data[el].mbt_prozess_true);
  }

  return {
    chart1: {
      series: [
        {
          name: "Number of delivery date reductions to process",
          data: [
            data[select1Element] === undefined
              ? 0
              : data[select1Element]?.verzoegerung_true,
          ],
        },
        {
          name: "Number of successful truncations",
          data: [
            data[select1Element] === undefined
              ? 0
              : data[select1Element]?.verzoegerung_false,
          ],
        },
        {
          name: "Number of open requests",
          data: [
            data[select1Element] === undefined
              ? 0
              : data[select1Element]?.mbt_prozess_true,
          ],
        },
      ],
    },
    chart2: {
      series: [
        {
          name: "Number of delivery date reductions to process",
          data: verzoegerung_true,
        },
        {
          name: "Number of successful truncations",
          data: verzoegerung_false,
        },
        {
          name: "Number of open requests",
          data: mbt_prozess_true,
        },
      ],
      categories: getKeysDiagram4(data),
    },
  };
};

// diagram 6
export const getKeysDiagram6 = (data) => {
  return Object.keys(data);
};

export const getDataForSelectDiagram6 = (data) => {
  return Object.keys(data).map((item) => ({
    value: item,
    label: item,
  }));
};

export const getDiffSeconds = (curr, prev) => {
  const currSec = curr[0] * 3600 + curr[1] * 60;
  const prevSec = prev[0] * 3600 + prev[1] * 60;

  return currSec - prevSec;
};

export const getPercent = (curr, prev) => {
  return (
    Math.round(
      prev[0] + prev[1] === 0
        ? 100
        : (Math.round(
            ((curr[0] * 3600 + curr[1] * 60) /
              (prev[0] * 3600 + prev[1] * 60)) *
              100
          ) /
            100) *
            100 *
            10
    ) / 10
  );
};

const getNextDay = (day) => moment(day).add(1, "d");

const getInterval = (name, selectPeriod, data, minDate) => {
  if (selectPeriod === "current") {
    if (name === "1W")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(1, "w")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        getDateOrCurrentDate(),
      ];
    if (name === "1M")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(1, "month")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        getDateOrCurrentDate(),
      ];
    if (name === "6M")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(6, "month")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        getDateOrCurrentDate(),
      ];
    if (name === "1Y")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(1, "year")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        getDateOrCurrentDate(),
      ];
  } else {
    if (name === "1W")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(2, "w")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        moment(getDateOrCurrentDate(data))
          .subtract(1, "w")
          .format("YYYY-MM-DD"),
      ];
    if (name === "1M")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(2, "month")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        moment(getDateOrCurrentDate(data))
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
      ];
    if (name === "6M")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(1, "year")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        moment(getDateOrCurrentDate(data))
          .subtract(6, "month")
          .format("YYYY-MM-DD"),
      ];
    if (name === "1Y")
      return [
        moment(getDateOrCurrentDate(data))
          .subtract(2, "year")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        moment(getDateOrCurrentDate(data))
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
      ];
  }
};

const getNextIntervalDate = (name, data) => {
  if (name === "1W")
    return moment(getDateOrCurrentDate(data)).add(1, "w").format("YYYY-MM-DD");

  if (name === "1M")
    return moment(getDateOrCurrentDate(data))
      .add(1, "month")
      .format("YYYY-MM-DD");

  if (name === "6M")
    return moment(getDateOrCurrentDate(data))
      .add(6, "month")
      .format("YYYY-MM-DD");

  if (name === "1Y")
    return moment(getDateOrCurrentDate(data))
      .add(1, "year")
      .format("YYYY-MM-DD");
};

export const getHoursAndMinutes = (value) => {
  if (value) {
    const hours = Math.floor(value);

    const minute = Math.round(
      (Math.round(value * 10) / 10 - Math.floor(Math.round(value * 10) / 10)) *
        60
    );

    return [hours, minute];
  }
  return [0, 0];
};

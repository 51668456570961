import React, { useEffect, useState } from "react";
import { Styled } from "./diagram6Chart.styled";
import ChartDateButtons from "../../../common/ChartDateButtons";
import ResponseTimeTimeProgress from "../../LeftSide/ResponseTimeTimeProgress";
import {
  getDataForSelectDiagram6,
  getHoursAndMinutes,
} from "../../../middleware/math";
import { arrButtonsName } from "../../../const/const";

const Diagram6Chart = ({
  selectElement,
  handleChange,
  data,
  buttonDateIndex,
  hanldeChangeDateIndex,
  buttonIndex,
}) => {
  const [selectData, setSelectData] = useState(
    getDataForSelectDiagram6(
      data[0][arrButtonsName[buttonDateIndex[buttonIndex]]]
    )
  );

  const [reminderCurrent, setReminderCurrent] = useState();

  const [timeCurrent, setTimeCurrent] = useState();

  useEffect(() => {
    // Reminder
    const resReminderCurrent = getHoursAndMinutes(
      data[0][arrButtonsName[buttonDateIndex[buttonIndex]]][selectElement]
        ?.erinnerungen
    );
    setReminderCurrent(resReminderCurrent);

    // Time
    const resTimeCurrent = getHoursAndMinutes(
      data[0][arrButtonsName[buttonDateIndex[buttonIndex]]][selectElement]
        ?.bearbeitungszeit
    );
    setTimeCurrent(resTimeCurrent);

    setSelectData(
      getDataForSelectDiagram6(
        data[0][arrButtonsName[buttonDateIndex[buttonIndex]]]
      )
    );
  }, [buttonDateIndex[buttonIndex], selectElement]);

  return (
    <Styled.MainBox>
      <Styled.HeaderBox>
        <Styled.GroupNameSelect>
          <Styled.HeaderName>
            Antwortzeit pro Anfrage nach Disponenten ID
          </Styled.HeaderName>
          {arrButtonsName[buttonDateIndex[buttonIndex]] && (
            <Styled.SelectStyled
              defaultValue={""}
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={selectData}
              value={selectElement}
            />
          )}
        </Styled.GroupNameSelect>
        <Styled.HeaderControl>
          <ChartDateButtons
            buttonDateIndex={buttonDateIndex[buttonIndex]}
            hanldeChangeDateIndex={hanldeChangeDateIndex}
          />
        </Styled.HeaderControl>
      </Styled.HeaderBox>
      <Styled.GroupContent>
        {reminderCurrent && (
          <ResponseTimeTimeProgress
            value={reminderCurrent[0] + " Std " + reminderCurrent[1] + " Min"}
            description="Erinnerungen um eine Liefertermin- verkürzungen zu beantworten"
            selectButtonName={arrButtonsName[buttonDateIndex[buttonIndex]]}
          />
        )}
        {timeCurrent && (
          <ResponseTimeTimeProgress
            value={timeCurrent[0] + " Std " + timeCurrent[1] + " Min"}
            description="Durchschnittliche Tage um eine Lieferterminverkürzungen zu beantworten"
            selectButtonName={arrButtonsName[buttonDateIndex[buttonIndex]]}
          />
        )}
      </Styled.GroupContent>
    </Styled.MainBox>
  );
};

export default Diagram6Chart;

import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  height: 60px;
  background: #f2f2f5;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoxYear = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #74788d;
  margin-left: 40px;
`;

export const Styled = {
  MainBox,
  BoxYear,
};

import React, { useState } from "react";
import { Styled } from "./diagram4Chart.styled";
import ReactApexChart from "react-apexcharts";
import ChartDateButtons from "../../../common/ChartDateButtons";

const Diagram4Chart = ({
  header1,
  series1,
  options1State,
  selectDataArr1,
  header2,
  series2,
  options2State,
  selectDataArr2,
  handleRemoveRow2,
  handleAddRow2,
  hanldeChangeDateIndex,
  buttonDateIndex,
  handleSetSelect1Element,
  defaultSettingDiagram,
  select1Element,
  select2Element,
  handleSetSelect2Element,
}) => {
  const handleChange = (data) => {
    handleSetSelect1Element(data);
  };

  const handleChange2 = (data) => {
    handleAddRow2(data);
    handleSetSelect2Element(data);
  };

  return (
    <Styled.MainBox>
      <Styled.HeaderBox>
        <Styled.GroupNameSelect>
          <Styled.HeaderName>{header1}</Styled.HeaderName>
          <Styled.SelectStyled
            defaultValue={select1Element}
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={selectDataArr1}
            value={select1Element}
          />
        </Styled.GroupNameSelect>
        <Styled.HeaderControl>
          <ChartDateButtons
            buttonDateIndex={buttonDateIndex}
            hanldeChangeDateIndex={hanldeChangeDateIndex}
          />
        </Styled.HeaderControl>
      </Styled.HeaderBox>

      <ReactApexChart
        options={options1State}
        series={series1}
        type="bar"
        height={120}
        width={800}
      />

      <Styled.HeaderName>{header2}</Styled.HeaderName>

      <Styled.GroupChartAndRemove>
        <Styled.ChartBox>
          <ReactApexChart
            options={options2State}
            series={series2}
            type="bar"
            height={
              series2.length !== 0
                ? defaultSettingDiagram.startDif + series2[0]?.data?.length * 25
                : 0
            }
            width={770}
          />
        </Styled.ChartBox>
        <Styled.RemoveBox>
          {series2[0]?.data.map((item, index) => (
            <Styled.RemoveIcon
              key={index}
              top={index === 0 ? 34 : 25 * index + 34}
              onClick={() => handleRemoveRow2(index)}
            />
          ))}
        </Styled.RemoveBox>
      </Styled.GroupChartAndRemove>

      <Styled.GroupNameSelectAdd>
        <Styled.HeaderNameAdd>Add new...</Styled.HeaderNameAdd>
        <Styled.SelectStyled
          defaultValue="choose"
          style={{
            width: 120,
          }}
          onChange={handleChange2}
          options={selectDataArr2}
          value={select2Element}
        />
      </Styled.GroupNameSelectAdd>
    </Styled.MainBox>
  );
};

export default Diagram4Chart;

import {Spin} from "antd"
import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  min-height: 100vh;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 171px);
  background-color: #f8f8fb;
`

export const List = styled.div`
  display: flex;
  margin: 0px;
`

export const ListOL = styled.ol`
  padding-left: 20px;
`
export const SpinBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SpinStyle = styled(Spin)``

export const MainContentSpinnerWrapper = styled.div`
  display: flex;
  height: 100vh;
  max-height: calc(100vh - 225px);
  justify-content: center;
  align-items: center;
`

export const Styled = {
  MainBox,
  Content,
  List,
  ListOL,

  SpinStyle,
  SpinBox,
  MainContentSpinnerWrapper
}

import React, { useEffect, useState } from "react";
import { Styled } from "./diagram6.styled";

import ResponseTimeItem from "../../LeftSide/ResponseTimeItem";
import Diagram6Chart from "../../Charts/diagram6Chart";
import {
  getDataForSelectDiagram6,
  getHoursAndMinutes,
  getKeysDiagram6,
} from "../../../middleware/math";
import { arrButtonsName } from "../../../const/const";

// const data = {
//   0: {
//     "1W": {},
//     "1M": {},
//     "6M": {
//       E18: {
//         erinnerungen: 1,
//         bearbeitungszeit: 1,
//       },
//       E25: {
//         erinnerungen: 5,
//         bearbeitungszeit: 2,
//       },
//     },
//     "1Y": {
//       E18: {
//         erinnerungen: 10,
//         bearbeitungszeit: 3,
//       },
//       E25: {
//         erinnerungen: 20,
//         bearbeitungszeit: 4,
//       },
//       E28: {
//         erinnerungen: 30,
//         bearbeitungszeit: 5,
//       },
//     },
//   },
// };
const Diagram6 = ({ data }) => {
  const [buttonIndex, setButtonIndex] = useState(0);
  const [buttonDateIndex, setButtonDateIndex] = useState({ 0: 0, 1: 0 });

  const hanldeChangeDateIndex = (index) => {
    setButtonDateIndex((prev) => ({ ...prev, [buttonIndex]: index }));
  };

  const [selectElement, setSelectElement] = useState(
    getKeysDiagram6(
      data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
    )[buttonIndex]
  );

  const handleChange = (value) => {
    setSelectElement(value);
  };

  const [avarageCurrent, setAvarageCurrent] = useState(
    getHoursAndMinutes(
      data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]][
        selectElement
      ]?.bearbeitungszeit
    )
  );

  useEffect(() => {
    const resSelectElement = getDataForSelectDiagram6(
      data[0][arrButtonsName[buttonDateIndex[buttonIndex]]]
    );

    let select1ElementChanged = selectElement;
    if (resSelectElement.length === 0) {
      setSelectElement(undefined);
      select1ElementChanged = undefined;
    }
    if (
      resSelectElement.length > 0 &&
      selectElement !== undefined &&
      !resSelectElement.some((item) => item.value === selectElement)
    ) {
      setSelectElement(resSelectElement[0]?.value);
      select1ElementChanged = resSelectElement[0]?.value;
    }
    if (resSelectElement.length > 0 && selectElement === undefined) {
      setSelectElement(resSelectElement[0]?.value);
      select1ElementChanged = resSelectElement[0]?.value;
    }

    setAvarageCurrent(
      getHoursAndMinutes(
        data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]][
          select1ElementChanged
        ]?.bearbeitungszeit
      )
    );
  }, [selectElement, buttonDateIndex[buttonIndex]]);
  return (
    <Styled.MainBox>
      <Styled.LeftBox>
        <ResponseTimeItem
          color="yellow"
          header="Antwortzeit pro Anfrage"
          value={avarageCurrent[0] + " Std " + avarageCurrent[1] + " Min"}
          selectPeriod={arrButtonsName[buttonDateIndex[0]]}
        />
      </Styled.LeftBox>
      <Styled.RightBox>
        <Diagram6Chart
          data={data}
          selectElement={selectElement}
          handleChange={handleChange}
          buttonDateIndex={buttonDateIndex}
          hanldeChangeDateIndex={hanldeChangeDateIndex}
          buttonIndex={buttonIndex}
        />
      </Styled.RightBox>
    </Styled.MainBox>
  );
};

export default Diagram6;

import styled from "styled-components";
import { ReactComponent as Setting } from "../../images/ButtonLicenseIcon.svg";

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 111px;
  background: transparent;
`;

export const BoxAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
`;

export const BoxAvatarImg = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

export const BoxAvatarBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 50%;
  width: 30px;
  height: 30px;
`;

export const BoxDropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 21px;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
export const MenuButton = styled.button`
  background: white;
  color: "#555B6D";
  font-weight: "bold";
  border: 0;
  cursor: pointer;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoxSetting = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  margin-left: 30px;
`;

export const SettingButton = styled(Setting)`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const NameStyle = styled.span`
  color: black;
`;

export const Styled = {
  MainBox,
  BoxAvatar,
  BoxAvatarImg,
  BoxAvatarBorder,
  BoxDropDown,
  MenuButton,
  BoxSetting,
  SettingButton,
  NameStyle,
};

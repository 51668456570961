/* eslint-disable semi */
/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Space, Form, Input, Checkbox, Button, message } from "antd";
import { LockFilled, UserOutlined } from "@ant-design/icons";
import {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  GreyText,
  LoginWrapper,
  ForgotPassword,
  PrimaryButton,
  ContactLogo,
  ContactEllipse,
  ContactWrapper,
  OutterRowWrapper,
} from "./StyledComponents";

import SusiPortraitImg from "../../images/susi_portrait.png";
import Ellipse from "../../images/Ellipse.png";
import Contact from "../../images/Contact.png";
import { GRAPHQL_SERVER_URL, SWUI_BE_API_URL } from "../../const/const";
import axios from "axios";
import { DataContext } from "../../context/context";

const executeLogin = async (values, t) => {
  // fix
  // const data = await axios
  //   .post(`${SWUI_BE_API_URL}/api/login`, values, { withCredentials: true })
  //   .catch(() => message.error(t("login.loginFailed")));
  // return data;

  // fix
  // return [{ user_name: values.user_name }];
  return [{ user_name: values.email }];
};

const Login = ({ setIsVisible, handleAuthorized }) => {
  const { t } = useTranslation();
  const [formInstance] = Form.useForm();

  const { state, handlerSet } = useContext(DataContext);

  const onFinish = async (values) => {
    const response = await executeLogin(values, t);
    if (!response) return;
    // fix
    // if (!response || !response.data) return;
    setIsVisible(false);
    // fix
    handleAuthorized(response[0]); //response.data
  };

  const onFinishFailed = (error) => {
    message.error(t("login.loginFailed"));
  };

  return (
    <OutterRowWrapper>
      <LeftSideFormContainer>
        <SusiPortraitWrapper justify="space-evenly" align="middle" wrap={false}>
          <div>
            <OrangeText>{t("login.headlineWelcomeBack")}</OrangeText>
            <GreyText>{t("login.subHeadlineLoginAndStart")}</GreyText>
          </div>
          <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
        </SusiPortraitWrapper>
        <ContactWrapper>
          <ContactEllipse src={Ellipse} alt="contact background" />
          <ContactLogo src={Contact} alt="contact" />
        </ContactWrapper>
        <FormContainer>
          <Form
            onSubmit={executeLogin}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ user_type: "research_director" }}
            form={formInstance}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label={t("generic.forms.email.label")}
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihre E-Mail ein!",
                },
                { type: "email", message: "Dies ist keine gültige E-Mail!" },
              ]}
            >
              <Input placeholder={t("generic.forms.email.placeholder")} />
            </Form.Item>

            <Form.Item
              form={formInstance}
              name="password"
              label={t("generic.forms.password.label")}
              type="password"
              rules={[
                {
                  required: true,
                  message: t("generic.forms.password.errorNoValue"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("generic.forms.password.placeholder")}
              />
            </Form.Item>

            <LoginWrapper>
              <PrimaryButton
                type="submit"
                htmlType="submit"
                align="stretch"
                block
                id="buttonLogin"
              >
                {t("login.loginButton")}
              </PrimaryButton>
            </LoginWrapper>
          </Form>
        </FormContainer>
      </LeftSideFormContainer>
    </OutterRowWrapper>
  );
};

export default Login;

import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  padding: 18px;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #495057;
`;

export const HeaderControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderName2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #495057;
`;

export const Styled = {
  MainBox,
  HeaderBox,
  HeaderName,
  HeaderControl,
  HeaderName2,
};

import React, { useEffect, useState } from "react";
import { Styled } from "./diagram1Chart.styled";
import ReactApexChart from "react-apexcharts";
import ChartDateButtons from "../../../common/ChartDateButtons";
import {
  convertDataToDayStep,
  getSum,
  getSumForCurrentDay,
} from "../../../middleware/math";
import { arrButtonsName, chartDiagram1Option } from "../../../const/const";

const Diagram1Chart = ({
  buttonIndex,
  data,
  buttonDateIndex,
  hanldeChangeDateIndex,
}) => {
  const [statusMonthArrow, setStatusMonthArrow] = useState();
  const [statusYearArrow, setStatusYearArrow] = useState();

  useEffect(() => {
    setSeries1State(
      convertDataToDayStep(
        data[buttonIndex],
        arrButtonsName[buttonDateIndex[buttonIndex]]
      )
    );

    const resMonth =
      getSum(data[buttonIndex], "1M", "current") -
      getSum(data[buttonIndex], "1M", "prev");

    setStatusMonthArrow(resMonth > 0 ? "up" : resMonth < 0 ? "down" : "none");

    const resYear =
      getSum(data[buttonIndex], "1M", "current") -
      getSum(data[buttonIndex], "1M", "prev");

    setStatusYearArrow(resYear > 0 ? "up" : resYear < 0 ? "down" : "none");
  }, [buttonIndex, buttonDateIndex[buttonIndex]]);

  const [series1State, setSeries1State] = useState([]);

  const [options1State, setOptions1State] = useState(chartDiagram1Option);

  return (
    <Styled.MainBox>
      <Styled.HeaderBox>
        <Styled.HeaderName>Lieferterminverkürzungen</Styled.HeaderName>
        <Styled.HeaderControl>
          <ChartDateButtons
            buttonDateIndex={buttonDateIndex[buttonIndex]}
            hanldeChangeDateIndex={hanldeChangeDateIndex}
          />
        </Styled.HeaderControl>
      </Styled.HeaderBox>
      <Styled.GroupContent>
        <Styled.TodayBox>
          <Styled.TodayBoxTitle>Heute</Styled.TodayBoxTitle>
          <Styled.TodayBoxValue>
            {getSumForCurrentDay(data[buttonIndex])}
          </Styled.TodayBoxValue>
        </Styled.TodayBox>

        <Styled.MonthBox>
          <Styled.MonthBoxTitle>Diesen Monat</Styled.MonthBoxTitle>
          <Styled.MonthBoxGroupValue>
            <Styled.MonthBoxValueNumber>
              {getSum(data[buttonIndex], "1M", "current")}
            </Styled.MonthBoxValueNumber>
            <Styled.MonthBoxValuePercent>
              {getSum(data[buttonIndex], "1M", "prev") !== 0
                ? (Math.round(
                    ((getSum(data[buttonIndex], "1M", "current") -
                      getSum(data[buttonIndex], "1M", "prev")) /
                      getSum(data[buttonIndex], "1M", "prev")) *
                      10
                  ) /
                    10) *
                  100
                : 100}
              %
            </Styled.MonthBoxValuePercent>
            <Styled.MonthBoxValueArrow>
              {statusMonthArrow === "up" && <Styled.ItemValueArrowUp />}
              {statusMonthArrow === "down" && <Styled.ItemValueArrowDown />}
            </Styled.MonthBoxValueArrow>
          </Styled.MonthBoxGroupValue>
        </Styled.MonthBox>
        <Styled.YearBox>
          <Styled.MonthBoxTitle>Dieses Jahr</Styled.MonthBoxTitle>
          <Styled.MonthBoxGroupValue>
            <Styled.MonthBoxValueNumber>
              {getSum(data[buttonIndex], "1Y", "current")}
            </Styled.MonthBoxValueNumber>
            <Styled.MonthBoxValuePercent>
              {getSum(data[buttonIndex], "1Y", "prev") !== 0
                ? (Math.round(
                    ((getSum(data[buttonIndex], "1Y", "current") -
                      getSum(data[buttonIndex], "1Y", "prev")) /
                      getSum(data[buttonIndex], "1Y", "prev")) *
                      10
                  ) /
                    10) *
                  100
                : 100}
              %
            </Styled.MonthBoxValuePercent>
            <Styled.MonthBoxValueArrow>
              {statusYearArrow === "up" && <Styled.ItemValueArrowUp />}
              {statusYearArrow === "down" && <Styled.ItemValueArrowDown />}
            </Styled.MonthBoxValueArrow>
          </Styled.MonthBoxGroupValue>
        </Styled.YearBox>
      </Styled.GroupContent>
      <ReactApexChart
        options={options1State}
        series={series1State}
        type="area"
        height={350}
        width={800}
        // width={"100%"}
      />
    </Styled.MainBox>
  );
};

export default Diagram1Chart;
